import { ErrorBoundary } from 'react-error-boundary';

import { ErrorBoundary as Error } from '@/components/ErrorBoundaries/ErrorBoundary';
import { ConfigUI } from '@/components/Feature';
import { Header } from '@/features/ChatBotV2/components/Header';
import { Layout } from '@/features/ChatBotV2/components/Layout';

import { ChatPanel } from './components/ChatPanel';

export const ChatBotV2 = () => {
  return (
    <ErrorBoundary FallbackComponent={Error}>
      <ConfigUI feature='ask_needl_copilot'>
        <Layout header={<Header />} main={<ChatPanel />} />
      </ConfigUI>
    </ErrorBoundary>
  );
};
