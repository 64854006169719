import * as React from 'react';

import { ConfirmModal } from '@/components/ConfirmModal/ConfirmModal';
import { Events, useCustomEventListener } from '@/hooks/customEvent';
import { getAuthenticationStatusChannel } from '@/utils/broadcastMessages';

export const AuthenticationStatusListener = () => {
  const [showModal, setShowModal] = React.useState(false);

  useCustomEventListener(Events.LOGOUT, () => {
    setShowModal(true);
  });

  const reloadHandler = () => {
    window.location.reload();
  };

  React.useEffect(() => {
    const authenticationStatusChannel = getAuthenticationStatusChannel();

    const handleAuthenticationStatusChange = (event: MessageEvent) => {
      if (event.data && event.data.action === 'logout') {
        setShowModal(true);
      } else if (event.data && event.data.action === 'login') {
        window.location.reload();
      }
    };

    authenticationStatusChannel.addEventListener(
      'message',
      handleAuthenticationStatusChange
    );

    return () => {
      authenticationStatusChannel.removeEventListener(
        'message',
        handleAuthenticationStatusChange
      );
      authenticationStatusChannel.close();
    };
  }, []);

  return (
    <ConfirmModal
      modalHeading='Sign-In Required'
      modalContent="You have been logged out of your account. To sign in to Needl again, please click the 'Reload' button"
      primaryButtonText='Reload'
      showModal={showModal}
      closeOnOutsideClick={false}
      onPrimarySubmit={reloadHandler}
    />
  );
};
