import type * as React from 'react';

import { IconButton } from '@/components/IconButton/IconButton';
import ArrowLeft from '@/icons/expand_left.svg?react';
import ArrowRight from '@/icons/expand_right.svg?react';

export type SearchNavigatorType = {
  currentPage?: number;
  totalHits?: number;
  onBack?: () => void;
  onForward?: () => void;
};

export const SearchNavigator: React.FunctionComponent<SearchNavigatorType> = ({
  currentPage,
  totalHits,
  onBack,
  onForward,
}) => {
  return (
    <div className='flex h-auto items-center justify-center space-x-2'>
      <IconButton
        disabled={currentPage === 1}
        className='fill-current text-gray-500'
        onClick={onBack}
        label='navigate left'
        size='small'
        disableHover
      >
        <ArrowLeft />
      </IconButton>
      <div className='flex items-center justify-between space-x-2 text-gray-700 body-sm'>
        <span>{currentPage}</span>
        <span>of</span>
        <span>{totalHits}</span>
      </div>
      <IconButton
        disabled={currentPage === totalHits}
        className='fill-current text-gray-500'
        onClick={onForward}
        label='navigate right'
        size='small'
        disableHover
      >
        <ArrowRight />
      </IconButton>
    </div>
  );
};
