import * as React from 'react';
import styled from 'styled-components';
import { css } from 'twin.macro';

import { Popper } from '@/components/Popper/Popper';

export const CitationTooltip = ({ content, trigger }: ToolTipProps) => {
  const anchorRef = React.useRef<HTMLParagraphElement>(null);
  const popperRef = React.useRef<HTMLDivElement>(null);
  const parentRef = React.useRef<HTMLDivElement>(null);
  const [hovered, setHovered] = React.useState(false);
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    setHovered(true);
  };

  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      if (document.activeElement !== parentRef.current) {
        setHovered(false);
      }
    }, 200);
  };

  const handleTouchToggle = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    setHovered((prevHovered) => !prevHovered);
  };

  return (
    <div
      className='inline-flex'
      ref={parentRef}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
      <span ref={anchorRef} onTouchStart={handleTouchToggle}>
        {trigger}
      </span>
      <Popper
        isVisible={hovered}
        ref={popperRef}
        targetRef={anchorRef}
        placement='bottom-start'
        closeOnOutSideClick
        closePopper={handleMouseLeave}
        showAnimation={false}
      >
        <CitationWrapper>{content}</CitationWrapper>
      </Popper>
    </div>
  );
};

const CitationWrapper = styled.div`
  ${css`
    max-width: min(80vw, 500px);
  `}
`;

type ToolTipProps = {
  content: React.ReactNode;
  trigger: React.ReactNode;
};
