import * as React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { REPORT_ROUTES } from '@/nav-routes';
import { useNavigate } from '@/router';

const REPORT_FILE_IDS_QUERY_PARAM = 'report_file_ids';
const REPORT_FEED_ID_QUERY_PARAM = 'report_feed_id';
const REPORT_PREVIEW_SOURCE_QUERY_PARAM = 'deeplink';
const REPORT_PREVIEW_ORIGIN_QUERY_PARAM = 'report_preview_origin';

export const SEARCH_RESULT_PREVIEW_ORIGIN = 'report_search_result';

export const useReportPage = () => {
  const match = useRouteMatch<{ reportId: string }>(
    `${REPORT_ROUTES.REPORT_FULL_PAGE_ROUTES.BASE_ROUTE}/:reportId`
  );
  return {
    reportPageMatch: !!match,
    reportId: match && match.params ? match.params.reportId : null,
  };
};

export const useSelectReportFileIds = (initialIds?: string[]) => {
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (initialIds) {
      const params = new URLSearchParams(window.location.search);

      params.delete(REPORT_FILE_IDS_QUERY_PARAM);
      initialIds.forEach((id) =>
        params.append(REPORT_FILE_IDS_QUERY_PARAM, id)
      );

      navigate.replace(
        {
          pathname: window.location.pathname,
          search: params.toString(),
        },
        null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialIds]);

  const selectedIds = React.useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.getAll(REPORT_FILE_IDS_QUERY_PARAM);
  }, [location.search]);

  const setSelectedIds = React.useCallback(
    (ids: string[]) => {
      const params = new URLSearchParams(window.location.search);

      params.delete(REPORT_FILE_IDS_QUERY_PARAM);
      ids.forEach((id) => params.append(REPORT_FILE_IDS_QUERY_PARAM, id));

      navigate.replace(
        {
          pathname: window.location.pathname,
          search: params.toString(),
        },
        null
      );
    },
    [navigate]
  );

  return {
    selectedIds,
    setSelectedIds,
  };
};

export const useReportFeedId = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const reportFeedId = React.useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get(REPORT_FEED_ID_QUERY_PARAM) || null;
  }, [location.search]);

  const setReportFeedId = React.useCallback(
    (feedId: string | null) => {
      const params = new URLSearchParams(window.location.search);

      if (feedId) {
        params.set(REPORT_FEED_ID_QUERY_PARAM, feedId);
      } else {
        params.delete(REPORT_FEED_ID_QUERY_PARAM);
      }

      navigate.replace(
        {
          pathname: window.location.pathname,
          search: params.toString(),
        },
        null
      );
    },
    [navigate]
  );

  return {
    reportFeedId,
    setReportFeedId,
  };
};

export const useReportPreviewSource = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const previewSourceId = React.useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get(REPORT_PREVIEW_SOURCE_QUERY_PARAM) || null;
  }, [location.search]);

  const previewOrigin = React.useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get(REPORT_PREVIEW_ORIGIN_QUERY_PARAM) || null;
  }, [location.search]);

  const setPreviewSourceId = React.useCallback(
    (previewSourceId: string | null, origin?: string) => {
      const params = new URLSearchParams(window.location.search);

      if (previewSourceId) {
        params.set(REPORT_PREVIEW_SOURCE_QUERY_PARAM, previewSourceId);
        if (origin) {
          params.set(REPORT_PREVIEW_ORIGIN_QUERY_PARAM, origin);
        }
      } else {
        params.delete(REPORT_PREVIEW_ORIGIN_QUERY_PARAM);
        params.delete(REPORT_PREVIEW_SOURCE_QUERY_PARAM);
      }

      navigate.replace(
        {
          pathname: window.location.pathname,
          search: params.toString(),
        },
        null
      );
    },
    [navigate]
  );

  return {
    previewSourceId,
    previewOrigin,
    setPreviewSourceId,
  };
};
