import styled from 'styled-components';
import tw from 'twin.macro';

export const Layout = ({ header, main }: LayoutType) => {
  return (
    <Container>
      <header className='bg-white'>{header}</header>
      <main className='flex-1 border overflow-hidden bg-gray-100'>{main}</main>
    </Container>
  );
};

const Container = styled.div`
  ${tw`flex flex-1 flex-col w-full h-full bg-gradient-to-br from-bluelagoon-600 to-tapestry-600`}
`;

type LayoutType = {
  header: React.ReactNode;
  main: React.ReactNode;
};
