import type { AxiosInstance } from 'axios';
import axios from 'axios';
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';

import { getIdToken, signOut } from './components/AuthProvider';
import { Events } from './hooks/customEvent';
import { extractAppType } from './utils/appTypes';
import { postLogoutMessage } from './utils/broadcastMessages';
import { postDebugLogs } from './utils/postDebugLogs';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 0,
});

/**
 * instance for sending logs without auth, no headers
 */
const axiosDebugInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 0,
});

const axiosWithRetryInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 0,
});

axiosRetry(axiosWithRetryInstance, {
  retries: 2,
  retryDelay: () => 300,
  retryCondition: (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 404) {
      return false;
    }

    return isNetworkOrIdempotentRequestError(error);
  },
});

const promptUserToReloadAndLogin = () => {
  window.dispatchEvent(new CustomEvent(Events.LOGOUT));
  postLogoutMessage();
  postDebugLogs('No JWT token found, prompting user to reload and login');
  signOut();
};

const attachInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const jwt = await getIdToken();

      if (!jwt) {
        promptUserToReloadAndLogin();
        return new Promise(() => {
          // empty promise to stop the request
        });
      }

      config.headers.token = jwt;
      config.headers['app-type'] = extractAppType();

      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (res) => res,
    async (error) => {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.headers['x-response-origin'] === 'backend_express'
      ) {
        error.config._retryCount = error.config._retryCount || 0;
        error.config._retryCount += 1;

        if (error.config._retryCount > 2) {
          postDebugLogs(
            'Got 403, tried to get new JWT token 2 times already, prompting user to reload and login'
          );
          return Promise.reject(error);
        }

        try {
          postDebugLogs('Got 403, trying to get new JWT token');
          const jwt = await getIdToken();

          if (!jwt) {
            throw new Error('No JWT token');
          }

          error.config.headers = error.config.headers || {};
          error.config.headers.token = jwt;

          return axiosInstance.request(error.config);
        } catch (e) {
          promptUserToReloadAndLogin();
          return Promise.reject(e);
        }
      }
      return Promise.reject(error);
    }
  );
};

attachInterceptors(axiosInstance);
attachInterceptors(axiosWithRetryInstance);

export { axiosInstance, axiosDebugInstance, axiosWithRetryInstance };
