import * as React from 'react';
import type { QueryFunctionContext } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';

import { CONNECT_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';
import { timeInMs } from '@/constants/time';
import type { FeaturesConfigType } from '@/hooks/query/useFeatureConfig';
import { useConfigUI } from '@/hooks/query/useFeatureConfig';

import type { AppLibraryDataResponse } from './types';

const fetcher = ({ signal }: QueryFunctionContext) =>
  axiosInstance.get<AppLibraryDataResponse>(CONNECT_API_ROUTES.baseRoute(), {
    signal,
  });

const useAppLibraryData = () => {
  const {
    isLoading: isIntegrationsDataLoading,
    isError: isIntegrationsError,
    data: integrationsData,
    ...queryData
  } = useQuery(['integrations'], fetcher, {
    staleTime: timeInMs.FIFTEEN_SECONDS,
  });

  const { isLoading: isConfigLoading, data: config } = useConfigUI();

  const filteredData = React.useMemo(() => {
    if (!integrationsData?.data?.results || !config) {
      return integrationsData;
    }

    const filteredResults = integrationsData.data.results.filter((app) =>
      isAppAllowed(app?.label, config)
    );

    return {
      ...integrationsData,
      data: {
        ...integrationsData.data,
        results: filteredResults,
      },
    };
  }, [integrationsData, config]);

  const placeholderData = React.useMemo(
    () =>
      Array.from({ length: 5 }).map((_, i) => ({
        category: '',
        disabled: false,
        flag: false,
        label: `id-${i}`,
        private: false,
        tokenValid: false,
        value: '',
        isLoading: true,
      })),
    []
  );

  return React.useMemo(
    () => ({
      ...queryData,
      isLoading: isIntegrationsDataLoading || isConfigLoading,
      isError: isIntegrationsError,
      data: filteredData,
      placeholderData,
    }),
    [
      filteredData,
      isIntegrationsError,
      isIntegrationsDataLoading,
      isConfigLoading,
      placeholderData,
      queryData,
    ]
  );
};

const useRefetchAppLibraryData = (): (() => void) => {
  const queryClient = useQueryClient();

  return React.useCallback(() => {
    queryClient.invalidateQueries('integrations');
  }, [queryClient]);
};

const usePrefetchAppLibraryData = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery('integrations', {
      queryFn: fetcher,
      staleTime: timeInMs.FIFTEEN_SECONDS,
    });
  }, [queryClient]);
};

export {
  useAppLibraryData,
  usePrefetchAppLibraryData,
  useRefetchAppLibraryData,
};

export const isAppAllowed = (
  label: string,
  config: FeaturesConfigType
): boolean => {
  switch (label) {
    case 'teams':
      return config.apps_ms_teams;
    case 'gmail':
      return config.apps_gmail;
    case 'outlook':
      return config.apps_outlook;
    case 'twitter':
      return config.apps_twitter;
    case 'googledrive':
      return config.apps_google_drive;
    case 'evernote':
      return config.apps_evernote;
    case 'slack':
      return config.apps_slack;
    case 'sharepoint':
      return config.apps_sharepoint;
    case 'box':
      return config.apps_box;
    case 'onedrive':
      return config.apps_onedrive;
    case 'dropbox':
      return config.apps_dropbox;
    case 'onenote':
      return config.apps_onenote;
    case 'azure_storage':
      return config.apps_azure_blob;
    case 'telegram':
      return config.apps_telegram;
    case 'needldrive':
      return config.needldrive;
    case 'india_regulatory_data':
      return config.capital_markets_indian;
    case 'us_regulatory_data':
      return config.capital_markets_us;
    case 'nz_regulatory_data':
      return config.capital_markets_nz;
    default:
      return true;
  }
};
