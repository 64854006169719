import type {
  AskNeedlCitationType,
  AskNeedlResponseType,
} from '@/components/ChatBot/hooks';

export function formatForShare(
  prompt: string,
  data: AskNeedlResponseType | undefined,
  type: 'text'
): string | null;

export function formatForShare(
  prompt: string,
  data: AskNeedlResponseType | undefined,
  type: 'object'
): FormattedData | null;

export function formatForShare(
  prompt: string,
  data: AskNeedlResponseType | undefined,
  type: 'object' | 'text'
) {
  if (!data) {
    return null;
  }

  const citationMap = new Map<number, AskNeedlCitationType>();

  data.generated_answer?.sentences.forEach((answer) => {
    answer.citations.forEach((citation) => {
      citationMap.set(citation.id, citation);
    });
  });

  const answer =
    data.generated_answer?.sentences.reduce((previous, current) => {
      return `${previous}${current.sentence}${
        current.citations.length > 0
          ? ` ${current.citations
              .map((citation) => `[${citation.id}]`)
              .join(', ')}`
          : ''
      }`;
    }, '') ?? '';

  const sortedCitations = Array.from(citationMap.values()).sort(
    (a, b) => a.id - b.id
  );

  const citations = sortedCitations.reduce((acc, citation) => {
    if (!citation) {
      return acc;
    }

    return [
      ...acc,
      `[${citation.id}]:: ${
        citation.needl_document_link || citation.url || citation.context
      }`,
    ];
  }, [] as string[]);

  if (type === 'object') {
    return {
      answer,
      citations,
      prompt,
    };
  }

  let answerWithCitations = `Question: ${prompt}\n\n${answer}`;
  if (citations.length > 0) {
    answerWithCitations = `${answerWithCitations}\n\nCitations:\n${citations
      .join('\n')
      .replace(/::/g, ':')}`;
  }

  return answerWithCitations;
}

export type FormattedData = {
  prompt: string;
  answer: string;
  citations: string[];
};
