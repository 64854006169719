import type { BoundingBox } from '@/features/ChatBotV2/hooks/useAskNeedl';

export const getPDFBoundingBox = (boundingBox: BoundingBox) => {
  const coordinates = {
    x1: (boundingBox.coordinates[0]?.x ?? 0) / boundingBox.width,
    y1: (boundingBox.coordinates[0]?.y ?? 0) / boundingBox.height,
    x2: (boundingBox.coordinates[2]?.x ?? 0) / boundingBox.width,
    y2: (boundingBox.coordinates[2]?.y ?? 0) / boundingBox.height,
  };
  return {
    boundingBox: Object.values(coordinates),
    pageNumber: boundingBox.page_number,
  };
};
