import * as React from 'react';

import { CitationBase } from '@/components/Citation';
import type { AskNeedlCitationType } from '@/features/ChatBotV2/hooks/useAskNeedl';
import { getPDFBoundingBox } from '@/features/ChatBotV2/utils/getPDFBoundingBox';
import { useReportPreviewSource } from '@/features/NeedlReport/hooks/useReport';
import { useBoundingBox } from '@/hooks/useBoundingBox';

export const Citation = ({
  document_id,
  channel_id,
  access_key,
  category_tab,
  source,
  id,
  context,
  title_label,
  source_label,
  content_only,
  highlight_indexes,
  unix,
  isReportCitation,
  mime_type,
  document_highlight,
}: AskNeedlCitationType) => {
  const { setPreviewSourceId } = useReportPreviewSource();

  const boundingBoxes = React.useMemo(() => {
    return document_highlight ? [getPDFBoundingBox(document_highlight)] : [];
  }, [document_highlight]);

  const { updateBoundingBox } = useBoundingBox(document_id, boundingBoxes);

  const href = React.useMemo(() => {
    if (source && document_id && access_key && category_tab) {
      const params = new URLSearchParams([
        ['access_key', access_key],
        ['category_tab', category_tab],
      ]);

      if (channel_id) {
        params.append('channel_id', channel_id);
      }

      return `/preview/${source}/${document_id}?${params}`;
    }
  }, [source, document_id, access_key, category_tab, channel_id]) as string;

  const onLinkClick = React.useMemo(() => {
    if (isReportCitation && mime_type === 'pdf') {
      return () => {
        updateBoundingBox(0);
        setPreviewSourceId(document_id);
      };
    }

    return undefined;
  }, [
    isReportCitation,
    mime_type,
    updateBoundingBox,
    setPreviewSourceId,
    document_id,
  ]);

  return (
    <CitationBase
      href={!isReportCitation ? href : undefined}
      id={id}
      context={context}
      content_only={content_only}
      title_label={title_label}
      source_label={source_label}
      highlight_indexes={highlight_indexes}
      unix={unix}
      onLinkClick={onLinkClick}
    />
  );
};
