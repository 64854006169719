import * as React from 'react';

import { ML_API_ROUTES } from '@/api-routes';
import { useSSERequest } from '@/hooks/useSSERequest';

const ASK_NEEDL_SSE_EVENTS = {
  PROCESSING: 'PROCESSING', // keep alive events from the server
  INTERMEDIATE_STEP: 'INTERMEDIATE_STEP', // intermediate steps from the server
  GENERATED_ANSWER: 'GENERATED_ANSWER', // streaming answer from the server
};

const isIntermediateStep = (
  eventData: AskNeedlContentEventType
): eventData is IntermediateStepEventType =>
  eventData.status === ASK_NEEDL_SSE_EVENTS.INTERMEDIATE_STEP;

const isGeneratedAnswerStep = (
  eventData: AskNeedlContentEventType
): eventData is GeneratedAnswerEventType =>
  eventData.status === ASK_NEEDL_SSE_EVENTS.GENERATED_ANSWER;

export const useAskNeedl = () => {
  const [intermediateSteps, setIntermediateSteps] = React.useState<
    IntermediateStepType[]
  >([]);
  const [streamingAnswer, setStreamingAnswer] = React.useState('');
  const closeSSEConnectionRef = React.useRef<() => void>();

  const {
    isLoading,
    isStreaming,
    isError,
    events,
    data,
    makeSSERequest,
    clearData,
  } = useSSERequest<AskNeedlResponseType, AskNeedlContentEventType>(false);

  const handleContentEvent = React.useCallback(
    (eventData: AskNeedlContentEventType) => {
      if (isIntermediateStep(eventData)) {
        setIntermediateSteps((prevState) => [
          ...prevState.map((step) => ({
            ...step,
            loading: false,
          })),
          ...eventData.data.steps.map((step) => ({
            step,
            loading: true,
          })),
        ]);
      } else if (isGeneratedAnswerStep(eventData)) {
        // set all intermediate steps to not loading when i receive this event for first time
        setStreamingAnswer(
          (prevState) => prevState + eventData.data.answer_chunk
        );
      }
    },
    []
  );

  const askNeedl = async ({
    prompt,
    source,
    session_id,
    document_id,
    board_id,
    channel_id,
    pro,
  }: AskNeedlParamsType) => {
    const urlSearchParams = new URLSearchParams([
      ['prompt', prompt],
      ['stream', 'true'],
      ['timezone', Intl.DateTimeFormat().resolvedOptions().timeZone],
      ['pro', pro ? 'true' : 'false'],
      ...(source ? [['source', source]] : []),
      ...(session_id ? [['session_id', session_id]] : []),
      ...(document_id
        ? Array.isArray(document_id)
          ? document_id.map((id) => ['document_id', id])
          : [['document_id', document_id]]
        : []),
      ...(board_id ? [['board_id', board_id]] : []),
      ...(channel_id ? [['channel_id', channel_id]] : []),
    ]);

    const closeSSEConnection = await makeSSERequest(
      `${ML_API_ROUTES.askNeedl()}?${urlSearchParams.toString()}`,
      {
        method: 'GET',
      },
      ASK_NEEDL_SSE_EVENTS.GENERATED_ANSWER,
      handleContentEvent
    );

    closeSSEConnectionRef.current = closeSSEConnection;
  };

  const clearEventData = () => {
    setStreamingAnswer('');
    setIntermediateSteps([]);
    clearData();
    if (closeSSEConnectionRef.current) {
      closeSSEConnectionRef.current();
    }
  };

  return {
    isLoading,
    isStreaming,
    isError,
    data,
    intermediateSteps,
    streamingAnswer,
    events,
    askNeedl,
    clearEventData,
  };
};

export type IntermediateStepType = {
  step: string;
  loading: boolean;
};

type IntermediateStepEventType = {
  status: typeof ASK_NEEDL_SSE_EVENTS.INTERMEDIATE_STEP;
  data: {
    steps: string[];
  };
};

type GeneratedAnswerEventType = {
  status: typeof ASK_NEEDL_SSE_EVENTS.GENERATED_ANSWER;
  data: {
    answer_chunk: string;
  };
};

type AskNeedlContentEventType =
  | GeneratedAnswerEventType
  | IntermediateStepEventType;

type AskNeedlParamsType = {
  prompt: string;
  source?: string;
  session_id?: string;
  document_id?: string[] | string;
  board_id?: string;
  channel_id?: string;
  pro: boolean;
};

type Coordinate = {
  x: number;
  y: number;
};

export type BoundingBox = {
  coordinates: Coordinate[];
  height: number;
  width: number;
  page_number: number;
};

export type AskNeedlRetreivedResultType = {
  source: string;
  document_id: string;
  highlights: string[];
  needl_document_link?: string;
  original_source_link?: string;
  category_tab: string;
  access_key: string;
  utc_timestamp: number;
  title_label: string;
  mime_type?: string;
  highlighted_coordinates?: BoundingBox[];
};

export type AskNeedlSentenceType = {
  sentence: string;
  citations: AskNeedlCitationType[];
};

export type AskNeedlResponseType = {
  generated_answer?: {
    answer: string;
    sentences: AskNeedlSentenceType[];
  };
  retrieved_results?: AskNeedlRetreivedResultType[];
  message_id: string;
  session_id: string;
  answer_quality?: 'HIGH' | 'LOW';
  app_version: string;
  pro: boolean;
  query_params: {
    category: string;
    prompt: string;
    session_id?: string;
    document_id?: string;
    generate_answer?: boolean;
    stream?: boolean;
  };
  answer_type?: 'QNA' | 'SEARCH';
};

export type AskNeedlCitationType = {
  id: number;
  source: string;
  category_tab: string;
  document_id: string;
  channel_id?: string;
  access_key: string;
  context: string;
  needl_document_link?: string;
  original_source_link?: string;
  url?: string;
  title_label: string;
  source_label: string;
  content_only: string;
  highlight_indexes: { start: number; end: number }[];
  unix: string;
  isReportCitation?: boolean;
  mime_type?: string;
  document_highlight?: BoundingBox;
};
