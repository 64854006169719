import { useChatPanelCommonProps } from '@/features/ChatBotV2/components/ChatPanel/useChatPanelCommonProps';
import { Footer } from '@/features/ChatBotV2/components/Footer';
import { PlaceholderCard } from '@/features/ChatBotV2/components/PlaceholderCard';
import type { AskNeedlReportType } from '@/features/ChatBotV2/context';
import {
  NEEDL_UNIVERSE_ID,
  useAskNeedlContext,
} from '@/features/ChatBotV2/context';
import type { MessagingType } from '@/features/ChatBotV2/hooks/useFetchAskNeedlMessaging';
import {
  PLACEHOLDER_ASK_NEEDL_MESSAGES,
  useFetchAskNeedlMessaging,
} from '@/features/ChatBotV2/hooks/useFetchAskNeedlMessaging';
import type { SessionState } from '@/features/ChatBotV2/types';
import { useResponsiveConstants } from '@/hooks/responsive/useResponsiveConstants';
import { useLocationMatch } from '@/hooks/useLocationMatch';
import { getLogo } from '@/utils/getIcons';

import { NeedlReportPlaceholder } from './NeedlReportPlaceholder';

const DEFAULT_MESSAGING_TYPE = 'all';
const FEED_MESSAGING_TYPE = 'feed';
const DOCUMENT_MESSAGING_TYPE = 'document';

export const Placeholder = ({
  handleAskQuestion,
  pro,
  sessionState,
  report,
}: PlaceholderType) => {
  const {
    data: { searchWithin, currentFeedOrDocumentId },
  } = useAskNeedlContext();

  const { data: messages, isLoading, isError } = useFetchAskNeedlMessaging();
  const { askNeedlPageMatch } = useLocationMatch();
  const { mobile } = useResponsiveConstants();
  const { isStreaming, handleClear, snipNoteMutation, stopGenerating } =
    useChatPanelCommonProps({
      currentFeedOrDocumentId,
      searchWithin,
      pro,
      sessionState,
      report,
    });
  const StarGradient = getLogo('ask_needl_star_gradient');

  let messagesType: MessagingType = DEFAULT_MESSAGING_TYPE;

  if (
    searchWithin === 'feed' &&
    currentFeedOrDocumentId !== NEEDL_UNIVERSE_ID &&
    currentFeedOrDocumentId != 'home'
  ) {
    messagesType = FEED_MESSAGING_TYPE;
  }

  if (searchWithin === 'document') {
    messagesType = DOCUMENT_MESSAGING_TYPE;
  }

  const handleClick = ({ message }: ClickHandlerType) => {
    handleAskQuestion(message);
  };

  if (report.enabled) {
    return <NeedlReportPlaceholder report={report} />;
  }

  if (isError) {
    return null;
  }

  return (
    <div className='flex flex-col justify-center items-center gap-12 h-full'>
      <div className='w-6 h-6 text-black'>
        <StarGradient />
      </div>
      {!mobile && askNeedlPageMatch ? (
        <Footer
          onSubmit={handleAskQuestion}
          handleClear={handleClear}
          isLoading={snipNoteMutation.isLoading}
          hasMessages={sessionState.messages.length > 0}
          isStreaming={isLoading || isStreaming}
          stopGenerating={stopGenerating}
          rows={1}
        />
      ) : null}
      <div className='flex flex-wrap justify-center items-center gap-5'>
        {isLoading
          ? PLACEHOLDER_ASK_NEEDL_MESSAGES[messagesType]?.map(
              (message, index) => (
                <PlaceholderCard
                  key={index}
                  handleClick={() => null}
                  message={message}
                />
              )
            )
          : messages &&
            messages[messagesType]?.map((messageInfo) => (
              <PlaceholderCard
                key={messageInfo.question}
                handleClick={() =>
                  handleClick({ message: messageInfo.question })
                }
                message={messageInfo}
              />
            ))}
      </div>
    </div>
  );
};

type PlaceholderType = {
  handleAskQuestion: (message: string) => void;
  pro: boolean;
  sessionState: SessionState;
  report: AskNeedlReportType;
};

type ClickHandlerType = {
  message: string;
};
