import type { match } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

import {
  ASKNEEDL_FULLSCREEN_ROUTE,
  REPORT_ROUTES,
  SEARCH_ROUTES,
} from '@/nav-routes';

type MatchType = match<
  Partial<{
    category: string;
    source: string;
    docId: string;
  }>
> | null;

export const useLocationMatch = (): Record<string, MatchType> => {
  const anyTabMatch = useRouteMatch<{ source: string }>(
    '/:category/:source/:subcategory'
  );
  const chatsTabMatch = useRouteMatch<{ source: string }>(
    '/:category/:source/chats'
  );
  const sourcePreviewMatch = useRouteMatch<{ category: string }>(
    '/preview/:category/:deeplink'
  );
  const customFeedMatch = useRouteMatch('/custom-feed');
  const documentViewerMatch = useRouteMatch('/document');
  const imageViewerMatch = useRouteMatch('/image');
  const storageMatch = useRouteMatch('/integrations');
  const searchFeedMatch = useRouteMatch('/search');
  const notificationsFeedMatch = useRouteMatch('/notifications');
  const notebooksMatch = useRouteMatch('/notebooks');
  const feedsMatch = useRouteMatch('/feeds');
  const feedsHomeMatch = useRouteMatch('/feeds/home');
  const channelsMatch = useRouteMatch('/channels');
  const appsMatch = useRouteMatch('/apps');
  const connectMatch = useRouteMatch('/connect');
  const searchPageMatch = useRouteMatch(SEARCH_ROUTES.FULLSCREEN);
  const askNeedlPageMatch = useRouteMatch(ASKNEEDL_FULLSCREEN_ROUTE);
  const reportPageMatch = useRouteMatch(
    REPORT_ROUTES.REPORT_FULL_PAGE_ROUTES.BASE_ROUTE
  );

  return {
    chatsTabMatch,
    anyTabMatch,
    sourcePreviewMatch,
    customFeedMatch,
    documentViewerMatch,
    imageViewerMatch,
    storageMatch,
    searchFeedMatch,
    notificationsFeedMatch,
    notebooksMatch,
    feedsMatch,
    feedsHomeMatch,
    channelsMatch,
    appsMatch,
    connectMatch,
    searchPageMatch,
    askNeedlPageMatch,
    reportPageMatch,
  };
};
