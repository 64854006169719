import tw, { styled } from 'twin.macro';

import { useScrollIntoView } from '@/components/ChatBot/hooks';

export const MessageWrapper = ({
  avatar,
  prompt,
  helperText,
  variant = 'default',
}: MessageWrapperType) => {
  const Wrapper =
    variant === 'bot'
      ? BotContainer
      : variant === 'report'
      ? ReportMessageContainer
      : DefaultContainer;
  return (
    <div
      className={`w-full
     ${
       variant === 'report'
         ? 'flex flex-col gap-4 pt-4'
         : variant === 'bot'
         ? 'gap-x-2 items-start flex flex-row'
         : 'gap-x-2 items-start flex flex-row-reverse'
     }
    `}
      ref={useScrollIntoView()}
    >
      <div className={variant !== 'report' ? 'flex-shrink-0' : ''}>
        {avatar}
      </div>
      {prompt ? (
        <Wrapper role='listitem'>
          <div
            className={`w-full h-full text-sm ${
              variant !== 'report' ? 'px-4 py-2' : 'px-0 py-0'
            }`}
          >
            {prompt}
          </div>
        </Wrapper>
      ) : null}
      {helperText ? (
        <div className='italic body-sm-bold bg-gradient-to-r from-green-900 to-emerald-900 text-transparent bg-clip-text self-center'>
          {helperText}
        </div>
      ) : null}
    </div>
  );
};

const BotContainer = styled.div`
  ${tw`bg-emerald-100 rounded-2xl`}
`;

export const DefaultContainer = styled.div`
  ${tw`border border-gray-300 bg-white rounded-2xl`}
`;

const ReportMessageContainer = styled.div`
  ${tw`text-gray-700`}
`;

type MessageWrapperType = {
  avatar: React.ReactNode;
  prompt: Element | React.ReactNode | string;
  helperText?: string;
  variant?: 'bot' | 'default' | 'report';
};
