// @ts-strict-ignore
import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTypewriter } from 'react-simple-typewriter';

import { trackEvent } from '@/analytics/trackEvent';
import { Tooltip } from '@/components/Atoms/Tooltip/Tooltip';
import { Button } from '@/components/Button/Button';
import { ConfigUI } from '@/components/Feature';
import { IconButton } from '@/components/IconButton/IconButton';
import {
  type ClearButtonType,
  ClearButton,
} from '@/features/ChatBotV2/components/ClearButton';
import { Input } from '@/features/ChatBotV2/components/Input';
import type { AskNeedlReportType } from '@/features/ChatBotV2/context';
import { useChatHandler } from '@/features/ChatBotV2/hooks/useChatHandler';
import { useReportSourcesStatus } from '@/features/ChatBotV2/hooks/useReportSourcesStatus';
import { withAskNeedlStateSlice } from '@/features/ChatBotV2/hooks/withStateSlice';
import type { SessionState } from '@/features/ChatBotV2/types';
import { useHeaderText } from '@/features/Feed/hooks/useHeaderText';
import {
  useConfigUI,
  useFeatureConfigUI,
} from '@/hooks/query/useFeatureConfig';
import { useLocationMatch } from '@/hooks/useLocationMatch';
import { getLogo } from '@/utils/getIcons';

import { NeedlReportSelectedSources } from './NeedlReportSelectedSources';
import { ProToggle } from './ProToggle';
import { ScrollToBottomButton } from './ScrollToBottom';

const FooterImpl = ({
  scrollContainerRef,
  isLoading,
  onSubmit,
  handleClear,
  hasMessages,
  stopGenerating,
  rows,
  isStreaming = false,
  pro,
  sessionState,
  webSearch,
  disabled,
  report,
}: FooterImplType) => {
  const { feature: featureEnabled } = useFeatureConfigUI('ask_needl_pro');
  const { handleWebSearch, overlayType, category } = useChatHandler(webSearch);

  const formRef = React.useRef<HTMLFormElement>(null);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  const handleTextareResize = React.useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, []);

  const {
    params: { subCategory },
  } = useRouteMatch<{
    subCategory: string;
  }>(['/:category/:source/:subCategory']) || {
    params: {},
  };
  const { plainText } = useHeaderText();
  const { askNeedlPageMatch } = useLocationMatch();
  const { isLoading: isConfigLoading, data: config } = useConfigUI();

  const { completedCount } = useReportSourcesStatus(report);

  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    formRef.current?.reset();
    textareaRef.current?.focus();
    handleTextareResize();
  }, [isLoading, handleTextareResize]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.currentTarget;
    const formData = new FormData(form);
    const prompt = formData.get('botPrompt');

    if (!prompt) {
      return;
    }
    if (typeof prompt !== 'string') {
      return;
    }

    if (textareaRef.current) {
      textareaRef.current.value = '';
      textareaRef.current.style.height = 'auto';
    }

    onSubmit(prompt);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      formRef.current?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      );
      return;
    }
  };

  const handleInput = () => {
    handleTextareResize();
  };

  const clearButtonProps: ClearButtonType = {
    onClick: () => {
      handleClear();
      if (textareaRef.current) {
        textareaRef.current.value = '';
        textareaRef.current.focus();
      }
    },
    disabled: !hasMessages || isLoading,
  };

  const handleStop = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    formRef.current?.reset();
    stopGenerating();
    trackEvent('ask_needl_stop_generating', {
      source: askNeedlPageMatch ? 'Full Screen' : 'Copilot',
      tabName: subCategory,
      feedName: plainText,
    });
  };

  const SendIcon = getLogo('ask_needl_send_icon');
  const StarsIcon = getLogo('ask_needl_stars');
  const StopIcon = getLogo('ask_needl_stop_logo');
  const SearchOffIcon = getLogo('ask_needl_search_off_icon');
  const SearchOnIcon = getLogo('ask_needl_search_on_icon');

  const showSearch =
    !isConfigLoading &&
    config?.ask_needl_dataset_selection_needl_universe &&
    !report.enabled;

  const placeholderWords = React.useMemo(() => {
    return getPlaceholderMessages(hasMessages, featureEnabled, report.enabled);
  }, [hasMessages, featureEnabled, report]);

  const placeholderMessage = useTypewriter({
    words: placeholderWords,
    ...TYPEWRITER_CONFIG,
  })[0];

  const tooltipMessage = disabled
    ? 'Search status can only be changed in a new session. Please create a new session to enable or disable Search.'
    : 'Search the web';

  return (
    <footer
      className={`px-3 py-4 ${
        sessionState.messages.length === 0 && askNeedlPageMatch
          ? 'w-[90%] mr-3'
          : 'w-full'
      }  flex-shrink-0 flex flex-col gap-2 transition-shadow duration-300 ease-out relative`.trim()}
    >
      <div className='absolute w-10 h-10 z-20 left-1/2 -top-10'>
        <ScrollToBottomButton scrollRef={scrollContainerRef} />
      </div>
      <div className='flex-1 flex items-start gap-1'>
        <div className='h-9 flex items-center relative'>
          <ClearButton {...clearButtonProps} />
        </div>
        <form className='flex-1' ref={formRef} onSubmit={handleSubmit}>
          <Input
            ref={React.useCallback((ref) => {
              if (ref) {
                ref.focus();
              }
              textareaRef.current = ref;
            }, [])}
            className={`${
              pro && !report.enabled ? 'border !border-emerald-900' : ''
            }`}
            disabled={isLoading || (report.enabled && completedCount === 0)}
            onInput={handleInput}
            onKeyDown={handleKeyDown}
            placeholder={placeholderMessage}
            name='botPrompt'
            required
            label='Promt Needl bot'
            id='bot-promt'
            rows={rows}
            startAdorment={
              <div className='pl-2'>
                <StarsIcon
                  className={`w-4 h-4 ${
                    pro && !report.enabled
                      ? 'fill-emerald-900'
                      : 'fill-gray-400'
                  }`}
                />
              </div>
            }
            endAdornment={
              <div className={`flex flex-row justify-between items-center`}>
                <div>
                  {overlayType === 'preview' ||
                  category === 'preview' ? null : showSearch ? (
                    <Tooltip label={tooltipMessage}>
                      <Button
                        size='extrasmall'
                        disabled={disabled}
                        label='Web Search'
                        variant={webSearch ? 'primary' : 'action'}
                        className={`flex flex-row ${
                          webSearch
                            ? '!text-emerald-900 !bg-emerald-100 !border !border-emerald-600'
                            : ''
                        }`}
                        startIcon={
                          webSearch ? <SearchOnIcon /> : <SearchOffIcon />
                        }
                        onClick={handleWebSearch}
                      />
                    </Tooltip>
                  ) : null}
                </div>
                <div className='flex items-center gap-1'>
                  {!report.enabled ? (
                    <ConfigUI feature='ask_needl_pro'>
                      <ProToggle />
                    </ConfigUI>
                  ) : (
                    <NeedlReportSelectedSources />
                  )}

                  {isStreaming ? (
                    <Tooltip label='Stop Generating'>
                      <IconButton size='small' onClick={handleStop}>
                        <StopIcon
                          className={pro ? 'fill-emerald-900' : 'fill-gray-700'}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <IconButton
                      type='submit'
                      size='small'
                      disabled={
                        isLoading || (report.enabled && completedCount === 0)
                      }
                    >
                      <SendIcon
                        className={`${
                          isLoading || (report.enabled && completedCount === 0)
                            ? pro && !report.enabled
                              ? 'text-emerald-800'
                              : 'text-gray-400'
                            : pro && !report.enabled
                            ? 'text-emerald-900'
                            : 'text-gray-700'
                        }`}
                      />
                    </IconButton>
                  )}
                </div>
              </div>
            }
          />
        </form>
      </div>
    </footer>
  );
};

export const Footer = withAskNeedlStateSlice<FooterType, FooterImplType>(
  FooterImpl,
  (props, state) => {
    return {
      ...props,
      pro: state.data.pro,
      webSearch: state.data.webSearch,
      sessionState:
        state.data.sessions[state.data.searchWithin][
          state.data.currentFeedOrDocumentId
        ],
      disabled:
        state.data.sessions?.[state.data.searchWithin]?.[
          state.data.currentFeedOrDocumentId
        ]?.messages?.length !== 0,
      report: state.data.report,
    };
  }
);

const TYPEWRITER_CONFIG = {
  typeSpeed: 25,
  deleteSpeed: 20,
  loop: Number.POSITIVE_INFINITY,
  delaySpeed: 2000,
};

const getPlaceholderMessages = (
  hasMessages: boolean,
  proEnabled: boolean,
  reportEnabled: boolean
) => {
  return [
    hasMessages ? FOLLOW_UP_PLACEHOLDER : INITIAL_PLACEHOLDER,
    ...(proEnabled && !reportEnabled ? [PRO_PLACEHOLDER] : []),
    HELP_PLACEHOLDER,
  ];
};

const INITIAL_PLACEHOLDER = 'Ask me anything...';
const FOLLOW_UP_PLACEHOLDER = 'Ask follow up...';
const HELP_PLACEHOLDER = 'Add @help to your question if you need help';
const PRO_PLACEHOLDER =
  'Enable Pro for complex queries needing nuanced understanding or comprehensive answers.';

type FooterType = {
  isLoading?: boolean;
  onSubmit: (prompt: string) => void;
  hasMessages: boolean;
  handleClear: () => void;
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
  rows?: number;
  isStreaming?: boolean;
  stopGenerating: () => void;
};

type FooterImplType = FooterType & {
  pro: boolean;
  sessionState?: SessionState;
  webSearch?: boolean;
  disabled?: boolean;
  report: AskNeedlReportType;
};
