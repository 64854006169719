export const Events = {
  LOGOUT: 'needl-logout',
  FEED_REFRESH: 'needl-feed-refresh',
  CARD_DELETE: 'needl-card-delete',
  PORTFOLIO_CREATE: 'needl-portfolio-create',
  WHATSAPP_NUMBER_VERIFIED: 'needl-whatsapp-number-verified',
  STORAGE_ACCOUNT_ADD: 'needl-storage-account-add',
  FEED_CREATE: 'needl-feed-create',
} as const;

export type Event = typeof Events[keyof typeof Events];
