import * as React from 'react';

export const useHighlightNavigation = (
  totalHighlights: number,
  onUpdate?: (index: number) => void
) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handleBack = React.useCallback(() => {
    setCurrentIndex((prev) => {
      const newIndex = prev > 0 ? prev - 1 : Math.max(totalHighlights - 1, 0);
      onUpdate?.(newIndex);
      return newIndex;
    });
  }, [totalHighlights, onUpdate]);

  const handleForward = React.useCallback(() => {
    setCurrentIndex((prev) => {
      const newIndex = prev < totalHighlights - 1 ? prev + 1 : 0;
      onUpdate?.(newIndex);
      return newIndex;
    });
  }, [totalHighlights, onUpdate]);

  return {
    currentHighlightIndex: currentIndex,
    handleBack,
    handleForward,
  };
};
