import * as React from 'react';
import ReactHotToast from 'react-hot-toast';

import type { ReportConfigType } from './useFetchReportConfig';

export const useReportGeneratorState = (initialState: ReportGeneratorState) => {
  return React.useReducer(reducer, initialState);
};

export const createFileKey = (file: File) => `${file.name}-${file.size}`;

const reducer = (
  state: ReportGeneratorState,
  action: ReportGeneratorActions
): ReportGeneratorState => {
  switch (action.type) {
    case 'ADD_DOCUMENTS': {
      const { files, generateId } = action.payload;
      const { documents, config } = state;

      const existingFileKeys = new Set(
        documents.map((doc) => createFileKey(doc.file))
      );

      const newDocuments = files
        .filter((file) => !existingFileKeys.has(`${file.name}-${file.size}`))
        .map((file) => ({
          file,
          uploadProgress: 0,
          uploadStatus: 'PENDING' as UploadStatusType,
          ...(generateId && { id: crypto.randomUUID() }),
        }));

      const updatedDocuments = [...documents, ...newDocuments];

      if (updatedDocuments.length > config.file_count_limit) {
        ReactHotToast.error(
          `Only ${config.file_count_limit} files can be uploaded for a report.`,
          {
            duration: 2000,
          }
        );
      }

      return {
        ...state,
        documents: updatedDocuments.slice(0, config.file_count_limit),
      };
    }
    case 'REMOVE_DOCUMENT': {
      const { file } = action.payload;

      return {
        ...state,
        documents: state.documents.filter(
          (doc) => !(doc.file.name === file.name && doc.file.size === file.size)
        ),
      };
    }
    case 'CLEAR_ALL_DOCUMENTS': {
      return {
        ...state,
        documents: [],
      };
    }
    case 'UPDATE_UPLOAD_PROGRESS': {
      const { file, progress } = action.payload;

      const docIndex = state.documents.findIndex(
        (doc) => doc.file.name === file.name && doc.file.size === file.size
      );

      if (docIndex === -1) {
        return state;
      }

      state.documents[docIndex].uploadProgress = progress;

      if (progress === 100) {
        state.documents[docIndex].uploadStatus = 'DONE';
      }

      return {
        ...state,
        documents: state.documents,
      };
    }
    case 'UPDATE_UPLOAD_STATUS': {
      const { file, status, info } = action.payload;
      const updatedDocuments = state.documents.slice();

      const docIndex = updatedDocuments.findIndex(
        (doc) => doc.file.name === file.name && doc.file.size === file.size
      );

      if (docIndex === -1) {
        return state;
      }

      updatedDocuments[docIndex].uploadStatus = status;
      updatedDocuments[docIndex].info = info;

      return {
        ...state,
        documents: updatedDocuments,
      };
    }
    case 'UPDATE_MULTIPLE_FILES_UPLOAD_STATUS': {
      const { files, status } = action.payload;

      const fileKeysToUpdate = new Set(
        files.map((file) => createFileKey(file))
      );

      const updatedDocuments = state.documents.map((doc) => {
        const fileKey = createFileKey(doc.file);

        if (fileKeysToUpdate.has(fileKey)) {
          return {
            ...doc,
            uploadStatus: status,
          };
        }

        return doc;
      });

      return {
        ...state,
        documents: updatedDocuments,
      };
    }
    default:
      return state;
  }
};

export type ReportGeneratorState = {
  documents: DocumentType[];
  config: ReportConfigType;
};

type UploadStatusType = 'DONE' | 'FAIL' | 'PENDING';

export type DocumentType = {
  file: File;
  uploadProgress: number;
  uploadStatus: UploadStatusType;
  info?: DocInfoType;
  id?: string;
};

type DocInfoType = {
  source: string;
  bucket: string;
  key: string;
  metadata: {
    fileName: string;
    mimeType: string;
    guid: string;
  };
};

export type ReportGeneratorActions =
  | {
      type: 'ADD_DOCUMENTS';
      payload: {
        files: File[];
        generateId?: boolean;
      };
    }
  | {
      type: 'CLEAR_ALL_DOCUMENTS';
    }
  | {
      type: 'REMOVE_DOCUMENT';
      payload: {
        file: File;
      };
    }
  | {
      type: 'UPDATE_MULTIPLE_FILES_UPLOAD_STATUS';
      payload: {
        files: File[];
        status: UploadStatusType;
      };
    }
  | {
      type: 'UPDATE_UPLOAD_PROGRESS';
      payload: {
        file: File;
        progress: number;
      };
    }
  | {
      type: 'UPDATE_UPLOAD_STATUS';
      payload: {
        file: File;
        status: UploadStatusType;
        info?: DocInfoType;
      };
    };
