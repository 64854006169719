import styled from 'styled-components';
import tw from 'twin.macro';

import { ConfigUI } from '@/components/Feature';
import { ChatContainer } from '@/features/ChatBotV2/components/ChatContainer';
import { FixedContainer } from '@/features/ChatBotV2/components/FixedContainer';
import { Footer } from '@/features/ChatBotV2/components/Footer';
import {
  ExportToPdfButton,
  SaveToNoteButton,
} from '@/features/ChatBotV2/components/SaveToNoteButton';
import { withAskNeedlStateSlice } from '@/features/ChatBotV2/hooks/withStateSlice';
import { useResponsiveConstants } from '@/hooks/responsive/useResponsiveConstants';
import { useDevices } from '@/hooks/useDevices';
import { useForkRef } from '@/hooks/useForkRef';
import { usePrintDOMNode } from '@/hooks/usePrintDOMNode';
import { getCurrentDateString } from '@/utils/date';

import { Notice } from './Notice';
import type { useChatPanelPropsType } from './useChatPanelCommonProps';
import { useChatPanelCommonProps } from './useChatPanelCommonProps';

const ChatPanelFullScreenImpl = ({
  currentFeedOrDocumentId,
  searchWithin,
  sessionState,
  pro,
  report,
}: ChatPanelFullScreenImplType) => {
  const {
    isLoading,
    isStreaming,
    isError,
    handleAskQuestion,
    handleClear,
    saveToNoteHandler,
    snipNoteMutation,
    listContainerRef,
    stopGenerating,
  } = useChatPanelCommonProps({
    currentFeedOrDocumentId,
    searchWithin,
    sessionState,
    pro,
    report,
  });
  const { domNodeRef, printHandler } = usePrintDOMNode(
    `AskNeedl ` + getCurrentDateString()
  );
  const combinedRef = useForkRef(domNodeRef, listContainerRef);

  const downloadPdfHandler = () => {
    printHandler();
  };

  const { mobile } = useResponsiveConstants();
  const { native } = useDevices();

  const shouldRenderExportButton =
    sessionState.messages.length >= 2 &&
    (import.meta.env.VITE_HOST_ENV as string) === 'pwc';

  return (
    <>
      <div className='col-span-full'>
        <ChatContainerContainer isMobile={mobile}>
          <ChatContainer
            listContainerRef={combinedRef}
            isLoading={isLoading}
            isError={isError}
            handleAskQuestion={handleAskQuestion}
            pro={pro}
          />
        </ChatContainerContainer>
      </div>
      <FixedContainer type='bottom' offset={mobile && !native ? 45 : 0}>
        <div className='max-w-4xl w-full mx-auto sm:px-4'>
          <div className='flex justify-end items-center px-3 gap-5'>
            <ConfigUI feature='notebooks'>
              <div className='flex justify-end bg-gradient-to-t from-white to-transparent'>
                {sessionState.messages.length < 2 ? null : (
                  <SaveToNoteButton
                    disabled={isLoading || isError}
                    loading={snipNoteMutation.isLoading}
                    saveToNoteHandler={saveToNoteHandler}
                  />
                )}
              </div>
            </ConfigUI>
            {shouldRenderExportButton ? (
              <ExportToPdfButton
                disabled={isLoading || isError}
                loading={false}
                exportAsPDFHandler={downloadPdfHandler}
              />
            ) : null}
          </div>
          <div className='bg-white pb-1 sm:pb-2'>
            {!mobile && sessionState.messages.length === 0 ? null : (
              <Footer
                onSubmit={handleAskQuestion}
                handleClear={handleClear}
                isLoading={snipNoteMutation.isLoading}
                hasMessages={sessionState.messages.length > 0}
                isStreaming={isLoading || isStreaming}
                stopGenerating={stopGenerating}
                rows={1}
              />
            )}
            <Notice />
          </div>
        </div>
      </FixedContainer>
    </>
  );
};

/**
 * To vertically center the content, set the min height of this container
 * For desktop, container height = 100vh - 72px, subtract app header height
 * For mobile, container height = 100vh - (72px + 57px), subtract app header + bottom nav
 */
const ChatContainerContainer = styled.div<{ isMobile?: boolean }>`
  ${tw`max-w-4xl w-full mx-auto h-full flex flex-col items-center pb-36`}
  ${({ isMobile }) => (isMobile ? tw`pt-24 mt-4` : tw`pt-28`)}
  min-height: ${({ isMobile }) =>
    isMobile ? 'calc(100vh - 130px)' : 'calc(100vh - 72px)'};
`;

export const ChatPanelFullScreen = withAskNeedlStateSlice<
  Record<string, unknown>,
  ChatPanelFullScreenImplType
>(ChatPanelFullScreenImpl, (props, state) => {
  return {
    ...props,
    searchWithin: state.data.searchWithin,
    currentFeedOrDocumentId: state.data.currentFeedOrDocumentId,
    sessionState:
      state.data.sessions[state.data.searchWithin][
        state.data.currentFeedOrDocumentId
      ],
    pro: state.data.pro,
    report: state.data.report,
  };
});

type ChatPanelFullScreenImplType = useChatPanelPropsType;
