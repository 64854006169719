import * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

export const PlaceHolder = ({ placeholder }: PlaceHolderType) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (scrollRef.current && placeholder) {
      scrollRef.current.scrollLeft = scrollRef.current.scrollWidth;
    }
  }, [placeholder]);

  return (
    <PlaceHolderContainer ref={scrollRef}>
      <span className='whitespace-nowrap text-gray-500 text-sm'>
        {placeholder}
      </span>
    </PlaceHolderContainer>
  );
};

const PlaceHolderContainer = styled.div`
  ${tw`overflow-x-auto w-[90%] absolute top-1.5 left-2.5 pointer-events-none`}
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

type PlaceHolderType = {
  placeholder?: string;
};
