import { useRouteMatch } from 'react-router-dom';

import { useFeatureConfigUI } from '@/hooks/query/useFeatureConfig';
import { useResponsiveConstants } from '@/hooks/responsive/useResponsiveConstants';
import {
  ASKNEEDL_FULLSCREEN_ROUTE,
  REPORT_ROUTES,
  SEARCH_ROUTES,
} from '@/nav-routes';

export const useShowAskNeedlButton = () => {
  const { isLoading: isFeatureConfigLoading, feature } =
    useFeatureConfigUI('ask_needl_copilot');
  const { mobile } = useResponsiveConstants();
  const isSettings = useRouteMatch('/settings');
  const isNoteBooksRoute = useRouteMatch('/notebooks');
  const isAssitantsRoute = useRouteMatch('/managers/assistants');
  const isReportDashboardRoute = useRouteMatch(REPORT_ROUTES.DASHBOARD_ROUTE);
  const isReportPageRoute = useRouteMatch(
    REPORT_ROUTES.REPORT_FULL_PAGE_ROUTES.BASE_ROUTE
  );
  const isNeedlBoxRoute = useRouteMatch('/needlbox');
  const isSearchPageRoute = useRouteMatch(SEARCH_ROUTES.FULLSCREEN);
  const isAskNeedlPageRoute = useRouteMatch(ASKNEEDL_FULLSCREEN_ROUTE);

  return (
    !mobile &&
    !isSettings &&
    !isAssitantsRoute &&
    !isReportDashboardRoute &&
    !isReportPageRoute &&
    !isNoteBooksRoute &&
    !isNeedlBoxRoute &&
    !isSearchPageRoute &&
    !isFeatureConfigLoading &&
    !isAskNeedlPageRoute &&
    feature
  );
};
