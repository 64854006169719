import * as React from 'react';

const STATIC_LOADER_MESSAGES = [
  'Thinking',
  'Exploring insights',
  'Finalizing answer',
];

const DOTS_MAX_COUNT = 3;
const MESSAGE_TIMEOUT = 12000;
const DOT_TIMEOUT = 500;

export const useGetLoaderText = () => {
  const [loaderMessageIndex, setLoaderMessageIndex] = React.useState(0);
  const [dotCount, setDotCount] = React.useState(0);
  const intervalRef = React.useRef<NodeJS.Timeout | null>(null);
  const elapsedTime = React.useRef(0);

  React.useEffect(() => {
    const updateLoaderText = () => {
      elapsedTime.current += DOT_TIMEOUT;

      setDotCount((prev) => (prev % DOTS_MAX_COUNT) + 1);

      if (elapsedTime.current % MESSAGE_TIMEOUT === 0) {
        setLoaderMessageIndex((prev) =>
          prev < STATIC_LOADER_MESSAGES.length - 1 ? prev + 1 : prev
        );
      }
    };

    intervalRef.current = setInterval(updateLoaderText, DOT_TIMEOUT);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return `${STATIC_LOADER_MESSAGES[loaderMessageIndex]}${'.'.repeat(dotCount)}`;
};
