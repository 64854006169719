import { useFetchChannels } from '@/features/Channels/hooks/useFetchChannels';
import { useFetchNotebooks } from '@/features/Notes/hooks/useFetchNotebooks';
import { useFetchPrivateApps } from '@/hooks/query/useFetchPrivateApps';

import { useFetchFeeds } from './useFetchFeeds';
import { useFetchNeedlbox } from './useFetchNeedlbox';
import { useFetchPublicApps } from './useFetchPublicApps';
import { useUserStatus } from './useUserStatus';

export const useFeedMetaData = () => {
  const {
    data: privateAppData,
    isLoading: isPrivateAppDataLoading,
    isFetching: isPrivateAppDataFetching,
  } = useFetchPrivateApps();
  const {
    data: publicAppData,
    isLoading: isPublicAppDataLoading,
    isFetching: isPublicAppDataFetching,
  } = useFetchPublicApps();
  const {
    data: feedData,
    isLoading: isFeedDataLoading,
    isFetching: isFeedDataFetching,
  } = useFetchFeeds();
  const {
    channels,
    isLoading: isChannelsLoading,
    isFetching: isChannelsFetching,
  } = useFetchChannels();
  const {
    data: notebooks,
    isLoading: isNotebooksLoading,
    isFetching: isNotebooksFetching,
  } = useFetchNotebooks();
  const {
    data: needlboxData,
    isLoading: isNeedlboxDataLoading,
    isFetching: isNeedlboxDataFetching,
  } = useFetchNeedlbox();
  const { userStatusData, userStatusFetching, userStatusLoading } =
    useUserStatus();

  return {
    privateAppData,
    isPrivateAppDataLoading,
    isPrivateAppDataFetching,
    publicAppData,
    isPublicAppDataLoading,
    isPublicAppDataFetching,
    feedData,
    isFeedDataLoading,
    isFeedDataFetching,
    channels,
    isChannelsLoading,
    isChannelsFetching,
    needlboxData,
    isNeedlboxDataLoading,
    isNeedlboxDataFetching,
    userStatusData,
    userStatusFetching,
    userStatusLoading,
    notebooks,
    isNotebooksLoading,
    isNotebooksFetching,
  };
};
