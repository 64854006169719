import * as React from 'react';

import type { AskNeedlReportType } from '@/features/ChatBotV2/context';

export const useReportSourcesStatus = (report: AskNeedlReportType) => {
  return React.useMemo(() => {
    const sources = report.sources || [];
    const processingCount = sources.filter(
      (source) => source.status === 'PROCESSING'
    ).length;
    const completedCount = sources.filter(
      (source) => source.status === 'COMPLETED'
    ).length;
    const allProcessing = processingCount > 0 && completedCount === 0;
    return { processingCount, completedCount, allProcessing };
  }, [report]);
};
