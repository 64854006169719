import { Avatar } from '@/components/Avatar';
import { MessageWrapper } from '@/features/ChatBotV2/components/MessageWrapper';
import { useUserData } from '@/hooks/query/useUserData';

export const UserMessage = ({
  prompt,
  isReportMessage = false,
}: UserMessageType) => {
  const { data: user } = useUserData();
  return (
    <MessageWrapper
      avatar={
        isReportMessage ? null : (
          <Avatar
            size='xs'
            shape='circular'
            className='bg-emerald-900'
            {...(user &&
              user.name && {
                name: user.name,
              })}
          />
        )
      }
      prompt={
        <div className={`w-full ${isReportMessage ? 'text-lg' : ''}`}>
          {prompt}
        </div>
      }
      variant={isReportMessage ? 'report' : 'default'}
    />
  );
};

type UserMessageType = {
  prompt: string;
  isReportMessage?: boolean;
};
