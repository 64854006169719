import type { AskNeedlReportType } from '@/features/ChatBotV2/context';
import { useReportSourcesStatus } from '@/features/ChatBotV2/hooks/useReportSourcesStatus';

export const NeedlReportPlaceholder = ({
  report,
}: NeedlReportPlaceholderProps) => {
  const { processingCount, completedCount, allProcessing } =
    useReportSourcesStatus(report);

  if (!report.sources) {
    return null;
  }

  return (
    <div className='h-full'>
      <div className='flex flex-col gap-2'>
        <div className='text-xl text-gray-700'>
          {allProcessing ? 'Needl Report' : report.info?.name}
        </div>
        <div className='text-gray-700'>
          {allProcessing
            ? `${processingCount} source${
                processingCount === 1 ? '' : 's'
              } processing`
            : `${completedCount} source${
                completedCount === 1 ? '' : 's'
              } added`}
        </div>
        {allProcessing ? (
          <div className='text-gray-700'>
            AskNeedl will be ready once file is processed
          </div>
        ) : null}
      </div>
    </div>
  );
};

type NeedlReportPlaceholderProps = {
  report: AskNeedlReportType;
};
