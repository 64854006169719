import { Tooltip } from '@/components/Atoms/Tooltip/Tooltip';
import { IosSwitchV4 } from '@/components/Switch';
import { useAskNeedlDispatchContext } from '@/features/ChatBotV2/context';
import { ASK_NEEDL_ACTIONS } from '@/features/ChatBotV2/hooks/useAskNeedlState';
import { withAskNeedlStateSlice } from '@/features/ChatBotV2/hooks/withStateSlice';
import { useUpdateUserPreference } from '@/hooks/mutation/useUpdateUserPreference';

const ProToggleImpl = ({ checked, disabled }: ProToggleImplType) => {
  const dispatch = useAskNeedlDispatchContext();
  const updateUserPreferenceMutation = useUpdateUserPreference();

  const toggleProHandler = () => {
    if (disabled) {
      return;
    }

    updateUserPreferenceMutation.mutate({
      askneedl_pro: !checked,
    });

    dispatch({
      type: ASK_NEEDL_ACTIONS.TOGGLE_PRO,
    });
  };

  const tooltipMessage = disabled
    ? 'Pro status can only be changed in a new session. Please create a new session to enable or disable Pro.'
    : checked
    ? 'You are currently in Pro mode. Toggle off to disable Pro features.'
    : 'Enable Pro mode to access premium features and advanced capabilities.';

  return (
    <Tooltip label={tooltipMessage}>
      <div
        className={`flex items-center ${
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
      >
        <IosSwitchV4
          id='ask-needl-pro'
          className={disabled ? 'opacity-60' : ''}
          disabled={disabled}
          checked={checked}
          onChange={() => {
            toggleProHandler();
          }}
        />
        <label
          htmlFor='ask-needl-pro'
          className={`text-xs mr-1 ${disabled ? 'opacity-60' : ''} ${
            checked ? 'text-emerald-900' : ''
          }`}
        >
          Pro
        </label>
      </div>
    </Tooltip>
  );
};

export const ProToggle = withAskNeedlStateSlice<
  Record<string, unknown>,
  ProToggleImplType
>(ProToggleImpl, (props, state) => {
  return {
    ...props,
    checked: state.data.pro,
    disabled:
      state.data.sessions?.[state.data.searchWithin]?.[
        state.data.currentFeedOrDocumentId
      ]?.messages?.length !== 0,
  };
});

type ProToggleImplType = {
  checked: boolean;
  disabled: boolean;
};
