import * as React from 'react';

import { PlaceHolder } from './Placeholder';

export const Input = React.forwardRef<HTMLTextAreaElement, InputType>(
  (
    {
      startAdorment,
      endAdornment,
      label,
      rows = 3,
      className,
      onChange: onTextChange,
      placeholder,
      ...rest
    },
    ref
  ) => {
    const disabled = rest.disabled;
    const [showPlaceholder, setShowPlaceholder] = React.useState(true);

    return (
      <div
        className={`border border-gray-400 rounded-md bg-white ${
          disabled ? 'bg-gray-200' : ''
        } ${className || ''}`.trim()}
      >
        <div className='flex-1 flex-col'>
          <div className='flex flex-row items-center mb-4'>
            <div>{startAdorment}</div>
            <div className='flex-1 min-w-0 px-2 py-2 relative'>
              <fieldset
                disabled={disabled}
                className='disabled:opacity-70 group'
              >
                <label htmlFor={rest.id} className='sr-only'>
                  {label}
                </label>
                <textarea
                  onChange={(e) => {
                    setShowPlaceholder(e.target.value.length === 0);
                    if (onTextChange) {
                      onTextChange(e);
                    }
                  }}
                  {...rest}
                  ref={ref}
                  rows={rows}
                  className='block w-full resize-none max-h-28 border-0 bg-transparent text-gray-900 body-sm placeholder:text-gray-500 placeholder:italic focus:outline-none '
                />
              </fieldset>
              {showPlaceholder ? (
                <PlaceHolder placeholder={placeholder} />
              ) : null}
            </div>
          </div>
          <div className='ml-2'>{endAdornment}</div>
        </div>
      </div>
    );
  }
);

export const AdornmentContainer = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  return <div {...props} ref={ref} className='flex-shrink-0 self-end' />;
});

type InputType = Omit<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  'className' | 'id'
> & {
  startAdorment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  label: string;
  id: string;
  className?: string;
};
