import * as React from 'react';
import ReactHotToast from 'react-hot-toast';
import { useRouteMatch } from 'react-router-dom';

import { trackEvent } from '@/analytics/trackEvent';
import { useAppFeedMatch } from '@/components/AppHeader/hooks/useCurrentFeedOption';
import { useSnipeNote } from '@/components/ChatBot/hooks';
import { type FormattedData, formatForShare } from '@/components/ChatBot/utils';
import type {
  AskNeedlReportType,
  SearchWithinType,
} from '@/features/ChatBotV2/context';
import {
  NEEDL_UNIVERSE_ID,
  useAskNeedlDispatchContext,
} from '@/features/ChatBotV2/context';
import { useAskNeedl } from '@/features/ChatBotV2/hooks/useAskNeedl';
import { ASK_NEEDL_ACTIONS } from '@/features/ChatBotV2/hooks/useAskNeedlState';
import {
  type SessionState,
  isBotMessageState,
  isUserMessageState,
} from '@/features/ChatBotV2/types';
import {
  useReportPage,
  useSelectReportFileIds,
} from '@/features/NeedlReport/hooks/useReport';
import { useBoardFromURL } from '@/hooks/useBoardFromURL';
import { useCurrentDocument } from '@/hooks/useCurrentDocument';
import { useGetSearchType } from '@/hooks/useGetSearchType';
import { CommonErrorToast } from '@/utils/toasts';

export const useChatPanelLogic = ({
  currentFeedOrDocumentId,
  searchWithin,
  sessionState,
  pro,
  report,
}: ChatPanelLogicType) => {
  const abortController = React.useRef<AbortController | null>(null);
  const dispatch = useAskNeedlDispatchContext();

  const {
    params: { source },
  } = useRouteMatch<{
    source: string;
  }>(['/:category/:source/:subCategory']) || {
    params: {},
  };

  const isAppFeed = useAppFeedMatch();
  const searchType = useGetSearchType();

  const { reportPageMatch } = useReportPage();
  const { selectedIds } = useSelectReportFileIds();

  const lastUserMessage = React.useMemo(() => {
    if (sessionState.messages.length === 0) {
      return;
    }

    const userMessages = sessionState.messages.filter(isUserMessageState);

    if (userMessages.length === 0) {
      return;
    }

    const lastUserMessage = userMessages[userMessages.length - 1];
    return lastUserMessage;
  }, [sessionState.messages]);

  const {
    streamingAnswer,
    intermediateSteps,
    data: askNeedlData,
    askNeedl,
    clearEventData,
    isLoading,
    isStreaming,
    isError,
  } = useAskNeedl();

  const snipNoteMutation = useSnipeNote();
  const { board_id, channel_id } = useBoardFromURL() ?? {};
  const currentDocument = useCurrentDocument();

  React.useEffect(() => {
    // close the sse connection when the current feed or document id changes
    if (abortController.current) {
      abortController.current.abort();
    }
    clearEventData();

    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFeedOrDocumentId, searchWithin]);

  React.useEffect(() => {
    if (intermediateSteps && intermediateSteps.length > 0) {
      dispatch({
        type: ASK_NEEDL_ACTIONS.SET_SESSION_INTERMEDIATE_STEPS,
        payload: {
          botMessageId: lastUserMessage?.botMessageId ?? '',
          prompt: lastUserMessage?.prompt ?? '',
          intermediateSteps,
        },
      });
    }
  }, [dispatch, intermediateSteps, lastUserMessage]);

  React.useEffect(() => {
    if (streamingAnswer) {
      dispatch({
        type: ASK_NEEDL_ACTIONS.SET_SESSION_WHILE_STREAMING,
        payload: {
          botMessageId: lastUserMessage?.botMessageId ?? '',
          prompt: lastUserMessage?.prompt ?? '',
          streamingAnswer,
        },
      });
    }
  }, [dispatch, lastUserMessage, streamingAnswer]);

  React.useEffect(() => {
    if (askNeedlData) {
      dispatch({
        type: ASK_NEEDL_ACTIONS.SET_SESSION_AFTER_COMPLETE,
        payload: {
          botMessageId: lastUserMessage?.botMessageId ?? '',
          prompt: lastUserMessage?.prompt ?? '',
          askNeedlData,
        },
      });

      clearEventData();
    }
  }, [askNeedlData, clearEventData, dispatch, lastUserMessage]);

  const saveToNoteHandler = () => {
    if (!sessionState?.messages.length || sessionState?.messages.length < 2) {
      return;
    }

    const prompt = sessionState?.messages[0].prompt;
    const snipData = sessionState?.messages
      .filter(({ type }) => type === 'bot')
      .map((data) => {
        if (isBotMessageState(data)) {
          return formatForShare(data.prompt, data.data, 'object');
        }
        return null;
      })
      .filter(Boolean);

    if (!snipData) {
      CommonErrorToast();
      return;
    }

    snipNoteMutation.mutate(
      {
        conversation: snipData as FormattedData[],
        promt: prompt,
      },
      {
        onSuccess: () => {
          trackEvent('ask_needl_note_created', {
            question: prompt,
          });
          ReactHotToast.success('Saved to Needl Note!');
        },
        onError: () => {
          CommonErrorToast();
        },
      }
    );
  };

  const handleAskQuestion = async (prompt: string) => {
    if (prompt.length > 0) {
      clearEventData();
      dispatch({
        type: ASK_NEEDL_ACTIONS.SET_SESSION_ON_QUESTION,
        payload: prompt,
      });

      trackEvent('ask_needl_ques_asked', {
        question: prompt,
        'Pro Enabled': String(pro),
      });

      if (abortController.current) {
        abortController.current.abort();
      }
      abortController.current = new AbortController();

      await askNeedl({
        prompt,
        ...(isAppFeed && searchWithin === 'feed' ? { source } : {}),
        session_id: sessionState?.sessionId,
        ...(searchWithin === 'document' && {
          document_id: currentDocument?.deeplink,
          channel_id,
          ...(searchType && {
            board_id: searchType,
          }),
        }),
        ...(searchWithin === 'feed' && {
          board_id,
          channel_id,
          ...(reportPageMatch && { document_id: selectedIds }),
        }),
        ...(currentFeedOrDocumentId === NEEDL_UNIVERSE_ID && {
          board_id: NEEDL_UNIVERSE_ID,
        }),
        pro: report.enabled ? false : pro,
      });
    }
  };

  const handleClear = () => {
    if (abortController.current) {
      abortController.current.abort();
    }
    clearEventData();
    dispatch({
      type: ASK_NEEDL_ACTIONS.SET_SESSION_ON_CLEAR,
      payload: {
        searchWithin,
      },
    });

    trackEvent('ask_needl_new_topic');
  };

  const stopGenerating = React.useCallback(() => {
    if (abortController.current) {
      abortController.current.abort();
    }

    dispatch({
      type: ASK_NEEDL_ACTIONS.STOP_SESSION_WHILE_STREAMING,
      payload: {
        botMessageId: lastUserMessage?.botMessageId ?? '',
        prompt: lastUserMessage?.prompt ?? '',
      },
    });

    clearEventData();
  }, [dispatch, lastUserMessage, clearEventData]);

  return {
    handleAskQuestion,
    handleClear,
    hasMessages: sessionState?.messages.length > 0,
    saveToNoteHandler,
    isLoading,
    isStreaming,
    isError,
    snipNoteMutation,
    stopGenerating,
  };
};

type ChatPanelLogicType = {
  currentFeedOrDocumentId: string;
  searchWithin: SearchWithinType;
  sessionState: SessionState;
  pro: boolean;
  report: AskNeedlReportType;
};
