import * as React from 'react';

import {
  type AskNeedlActions,
  useAskNeedlState,
} from '@/features/ChatBotV2/hooks/useAskNeedlState';
import type { SessionState } from '@/features/ChatBotV2/types';
import type { BoardFileType } from '@/features/CustomFeedV2/common';
import type { ReportInfoType } from '@/features/NeedlReport/types';

export const getInitialState = ({
  searchWithin,
  currentFeedOrDocumentId,
  pro,
}: Partial<AskNeedlState>) => ({
  searchWithin: searchWithin || ('feed' as SearchWithinType),
  currentFeedOrDocumentId: currentFeedOrDocumentId || 'home',
  sessions: {
    feed: {
      [NEEDL_UNIVERSE_ID]: {
        messages: [],
      },
      home: {
        messages: [],
      },
      feed_id: {
        messages: [],
      },
    },
    document: {
      document_id: {
        messages: [],
      },
    },
  },
  pro: pro ?? false,
  webSearch: false,
  report: {
    enabled: false,
  },
});

export const AskNeedlContext = React.createContext<AskNeedlContextType | null>(
  null
);

export const AskNeedlDispatchContext =
  React.createContext<React.Dispatch<AskNeedlActions> | null>(null);

export const useAskNeedlContext = () => {
  const value = React.useContext(AskNeedlContext);

  if (!value) {
    throw new Error(
      'useAskNeedlContext must be used within a AskNeedlProvider'
    );
  }

  return value;
};

export const useAskNeedlDispatchContext = () => {
  const value = React.useContext(AskNeedlDispatchContext);

  if (!value) {
    throw new Error(
      'useAskNeedlDispatchContext must be used within a AskNeedlProvider'
    );
  }

  return value;
};

export const AskNeedlContextProvider: React.FunctionComponent<
  AskNeedlProviderType
> = ({ initialState, children }) => {
  const [data, dispatch] = useAskNeedlState(initialState);

  const value = React.useMemo(() => ({ data }), [data]);

  return (
    <AskNeedlContext.Provider value={value}>
      <AskNeedlDispatchContext.Provider value={dispatch}>
        {children}
      </AskNeedlDispatchContext.Provider>
    </AskNeedlContext.Provider>
  );
};

export const NEEDL_UNIVERSE_ID = 'needl_universe';

export type AskNeedlContextType = {
  data: AskNeedlState;
};

export type AskNeedlState = {
  searchWithin: SearchWithinType;
  currentFeedOrDocumentId: string;
  sessions: {
    [key in SearchWithinType]: {
      [key: string]: SessionState;
    };
  };
  pro: boolean;
  webSearch?: boolean;
  report: AskNeedlReportType;
};

export type AskNeedlReportType = {
  enabled: boolean;
  info?: ReportInfoType;
  sources?: BoardFileType[];
};

type AskNeedlProviderType = {
  initialState: AskNeedlState;
};

export type SearchWithinType = 'document' | 'feed';
