const SOURCE_BASE_ROUTE = '/source';

export const SOURCE_API_ROUTES = {
  baseRoute: () => SOURCE_BASE_ROUTE,
  publicSourceBoards: (source: string) =>
    `${SOURCE_BASE_ROUTE}/public/${source}/boards`,
  rss: () => `${SOURCE_BASE_ROUTE}/rss`,
  rssInfo: (rssId: string) => `${SOURCE_BASE_ROUTE}/rss/${rssId}`,
  needlBox: () => `${SOURCE_BASE_ROUTE}/needlbox`,
  needlWeb: () => `${SOURCE_BASE_ROUTE}/needlweb`,
  private: () => `${SOURCE_BASE_ROUTE}/private`,
  public: () => `${SOURCE_BASE_ROUTE}/public`,
} as const;
