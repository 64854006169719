import * as React from 'react';

import { useNavigate } from '@/router';

const broadcastDeeplinkMap: Record<string, BroadcastChannel> = {};

export const useCleanupBoundingBox = () => {
  const navigate = useNavigate();

  const cleanUpBoundingBox = React.useCallback(
    (deeplink: string) => {
      const params = new URLSearchParams(window.location.search);
      params.delete('boundingBox');
      params.delete('pageNumber');

      navigate.replace(
        {
          pathname: window.location.pathname,
          search: params.toString(),
        },
        null
      );

      const broadcast = broadcastDeeplinkMap[deeplink];
      if (broadcast) {
        broadcast.close();
        delete broadcastDeeplinkMap[deeplink];
      }
    },
    [navigate]
  );

  return {
    cleanUpBoundingBox,
  };
};

const useUpdateBoundingBox = (
  deeplink: string,
  boundingBoxData: BoundingBoxData[]
) => {
  const navigate = useNavigate();

  const updateBoundingBox = React.useCallback(
    (newIndex: number) => {
      if (!boundingBoxData.length || !deeplink) return;

      const validIndex = newIndex % boundingBoxData.length;
      const { boundingBox, pageNumber } = boundingBoxData[validIndex];

      const params = new URLSearchParams(window.location.search);
      params.set('boundingBox', JSON.stringify(boundingBox));
      params.set('pageNumber', String(pageNumber));

      navigate.replace(
        {
          pathname: window.location.pathname,
          search: params.toString(),
        },
        null
      );

      if (!broadcastDeeplinkMap[deeplink]) {
        broadcastDeeplinkMap[deeplink] = new BroadcastChannel(
          `pdfcitations-${deeplink}`
        );
      }

      broadcastDeeplinkMap[deeplink].postMessage({
        deeplink: deeplink,
        boundingBox,
        pageNumber,
      });
    },
    [deeplink, boundingBoxData, navigate]
  );

  return { updateBoundingBox };
};

export const useBoundingBox = (
  deeplink: string,
  boundingBoxData: BoundingBoxData[]
) => {
  const { updateBoundingBox } = useUpdateBoundingBox(deeplink, boundingBoxData);

  return { updateBoundingBox };
};

export type BoundingBoxData = {
  boundingBox: number[];
  pageNumber: number;
};
