import type { Theme } from '@material-ui/core/styles';
import { createStyles, withStyles } from '@material-ui/core/styles';
import type { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import MUISwitch from '@material-ui/core/Switch';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

export const IosSwitch = styled(Switch)(({ theme }) => ({
  width: 34,
  height: 18,
  padding: 0,
  margin: 4,
  '& .MuiSwitch-switchBase': {
    padding: 1,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff',
    },
  },
  '& .MuiSwitch-thumb': {
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.grey[500],
    opacity: 1,
    transition: theme.transitions.create(['background-color']),
  },
}));

export const IosSwitchV4 = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 34,
      height: 18,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: theme.palette.primary.main,
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 16,
      height: 16,
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.grey[500],
      opacity: 1,
      transition: theme.transitions.create(['background-color']),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: Props) => {
  return (
    <MUISwitch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

type Styles = Partial<Record<SwitchClassKey, string>> & {
  focusVisible?: string;
};

type Props = SwitchProps & {
  classes: Styles;
};
