import parse from 'html-react-parser';
import * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { SearchNavigator } from '@/components/Atoms/SearchNavigator/SearchNavigator';
import type { AskNeedlRetreivedResultType } from '@/features/ChatBotV2/hooks/useAskNeedl';
import { useHighlightNavigation } from '@/features/ChatBotV2/hooks/useHighlightNavigation';
import { getPDFBoundingBox } from '@/features/ChatBotV2/utils/getPDFBoundingBox';
import {
  SEARCH_RESULT_PREVIEW_ORIGIN,
  useReportPreviewSource,
} from '@/features/NeedlReport/hooks/useReport';
import { useBoundingBox } from '@/hooks/useBoundingBox';
import { getMimeIcon } from '@/utils/getMimeIcons';

export const SearchMessage = ({ results }: SearchMessageType) => {
  const { previewSourceId, previewOrigin, setPreviewSourceId } =
    useReportPreviewSource();

  if (results.length === 0) {
    return <div className='text-sm text-gray-700'>No search results found</div>;
  }

  return (
    <div className='flex flex-col gap-4'>
      <div className='text-sm text-gray-700'>Search results...</div>
      <div>
        {results.map((result) => {
          if (result.mime_type === 'pdf' && result.highlighted_coordinates) {
            return (
              <BoundingBoxResultItem
                key={result.document_id}
                result={result}
                onResultClick={() =>
                  setPreviewSourceId(
                    result.document_id,
                    SEARCH_RESULT_PREVIEW_ORIGIN
                  )
                }
                selected={
                  previewSourceId === result.document_id &&
                  previewOrigin === SEARCH_RESULT_PREVIEW_ORIGIN
                }
              />
            );
          }

          return (
            <ResultItem
              key={result.document_id}
              result={result}
              onResultClick={
                result.mime_type === 'pdf'
                  ? () => setPreviewSourceId(result.document_id)
                  : undefined
              }
              selected={previewSourceId === result.document_id}
            />
          );
        })}
      </div>
    </div>
  );
};

const ResultItem = ({ result, onResultClick, selected }: ResultItemType) => {
  const FileIcon = React.useMemo(
    () => getMimeIcon(result.mime_type || ''),
    [result.mime_type]
  );
  const { currentHighlightIndex, handleBack, handleForward } =
    useHighlightNavigation(result.highlights.length);

  return (
    <ResultWrapper selected={selected}>
      <div>
        <div className='w-[18px] h-[18px]'>
          <FileIcon />
        </div>
      </div>
      <div>
        {onResultClick ? (
          <SourceButton onClick={onResultClick}>
            {result.title_label}
          </SourceButton>
        ) : (
          <div className='font-medium text-gray-700 leading-5'>
            {result.title_label}
          </div>
        )}

        <div className='flex justify-between items-center'>
          <div className='text-gray-700'>{`${
            result.highlights.length
          } instance${result.highlights.length === 1 ? '' : 's'} found`}</div>
          <div>
            <SearchNavigator
              totalHits={result.highlights.length}
              onBack={handleBack}
              onForward={handleForward}
              currentPage={currentHighlightIndex + 1}
            />
          </div>
        </div>
        {result.highlights.length > 0 ? (
          <div className='mt-1 text-xs overflow-hidden'>
            {parse(result.highlights[currentHighlightIndex])}
          </div>
        ) : null}
      </div>
    </ResultWrapper>
  );
};

const BoundingBoxResultItem = ({
  result,
  onResultClick,
  selected,
}: BoundingBoxResultItemType) => {
  const FileIcon = React.useMemo(
    () => getMimeIcon(result.mime_type || ''),
    [result.mime_type]
  );

  const boundingBoxes = React.useMemo(() => {
    return (
      result.highlighted_coordinates?.map((coordinate) =>
        getPDFBoundingBox(coordinate)
      ) || []
    );
  }, [result]);

  const { updateBoundingBox } = useBoundingBox(
    result.document_id,
    boundingBoxes
  );

  const { currentHighlightIndex, handleBack, handleForward } =
    useHighlightNavigation(boundingBoxes.length, updateBoundingBox);

  const handleOpen = React.useCallback(() => {
    if (onResultClick) {
      updateBoundingBox(currentHighlightIndex);
      onResultClick();
    }
  }, [onResultClick, updateBoundingBox, currentHighlightIndex]);

  if (boundingBoxes.length === 0) {
    return null;
  }

  return selected ? (
    <ResultWrapper selected={selected}>
      <div>
        <div className='w-[18px] h-[18px]'>
          <FileIcon />
        </div>
      </div>
      <div className='w-full'>
        <div className='font-medium text-gray-700 leading-5'>
          {result.title_label}
        </div>
        <div className='flex justify-between items-center w-full'>
          <div className='text-gray-700'>{`${boundingBoxes.length} instance${
            boundingBoxes.length === 1 ? '' : 's'
          } found`}</div>
          <div>
            <SearchNavigator
              totalHits={boundingBoxes.length}
              onBack={handleBack}
              onForward={handleForward}
              currentPage={currentHighlightIndex + 1}
            />
          </div>
        </div>
      </div>
    </ResultWrapper>
  ) : (
    <BoundingBoxWrapperButton onClick={handleOpen}>
      <div>
        <div className='w-[18px] h-[18px]'>
          <FileIcon />
        </div>
      </div>
      <div className='w-full'>
        <div className='font-medium text-gray-700 leading-5'>
          {result.title_label}
        </div>
        <div className='flex w-full justify-between items-center mt-2'>
          <div className='text-gray-700'>{`${boundingBoxes.length} instance${
            boundingBoxes.length === 1 ? '' : 's'
          } found`}</div>
          <div className='text-xs text-gray-700'>Click to view in source</div>
        </div>
      </div>
    </BoundingBoxWrapperButton>
  );
};

const SourceButton = styled.button`
  ${tw`font-medium text-gray-700 leading-5 hover:text-emerald-900 hover:underline`}

  &:focus {
    ${tw`outline-none bg-gray-100`}
  }
`;

const BoundingBoxWrapperButton = styled.button`
  ${tw`flex gap-2 border-b py-6 border-gray-300 w-full text-left hover:bg-gray-200 px-2 rounded-md`}
`;

const ResultWrapper = styled.div<{ selected?: boolean }>`
  ${tw`flex gap-2 border-b py-6 border-gray-300 w-full px-2`}
  ${({ selected }) => selected && tw`bg-gray-200`}
`;

type SearchMessageType = {
  results: AskNeedlRetreivedResultType[];
};

type ResultItemType = {
  result: AskNeedlRetreivedResultType;
  onResultClick?: () => void;
  selected?: boolean;
};

type BoundingBoxResultItemType = ResultItemType;
