// @ts-strict-ignore
// import 'katex/dist/katex.min.css';

import Markdown from 'react-markdown';
// import rehypeKatex from 'rehype-katex';
import remarkGfm from 'remark-gfm';

// import remarkMath from 'remark-math';
import { CitationBase } from '@/components/Citation';
import { Citation } from '@/features/ChatBotV2/components/Citation';

export const RichMessage = ({
  markdown,
  className,
  isReportAnswer = false,
}: RichMessageProps) => {
  return (
    <div className={className}>
      <Markdown
        remarkPlugins={[remarkGfm]}
        // remarkPlugins={[remarkGfm, remarkMath]}
        // rehypePlugins={[rehypeKatex]}
        components={{
          a: !isReportAnswer ? CustomLink : ReportCustomLink,
          h1: StyledHeading,
          h2: StyledSubHeading,
          h3: StyledSubSubHeading,
          ul: StyledUnorderedList,
          ol: StyledOrderedList,
          div: StyledParagraph,
          p: StyledParagraph,
          table: StyledTable,
          thead: StyledTableHead,
          th: StyledTableHeader,
          td: StyledTableData,
        }}
      >
        {markdown}
      </Markdown>
    </div>
  );
};

type RichMessageProps = {
  markdown: string;
  className?: string;
  isReportAnswer?: boolean;
};

const CustomLink = ({
  href,
  children,
}: React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  if (href) {
    if (href.startsWith('/preview')) {
      const url = new URL(`https://www.needl.ai${href}`);
      const document_id = url.searchParams.get('document_id');
      const channel_id = url.searchParams.get('channel_id');
      const access_key = url.searchParams.get('access_key');
      const category_tab = url.searchParams.get('category_tab');
      const source = url.searchParams.get('source');
      const context = decodeURIComponent(url.searchParams.get('context'));
      const title_label = url.searchParams.get('title_label');
      const source_label = url.searchParams.get('source_label');
      const content_only = url.searchParams.get('content_only');
      const unix = url.searchParams.get('unix');
      const highlight_indexes = JSON.parse(
        url.searchParams.get('highlight_indexes') || '[]'
      );

      return (
        <Citation
          document_id={document_id}
          channel_id={channel_id}
          access_key={access_key}
          category_tab={category_tab}
          source={source}
          context={context}
          id={children as number}
          title_label={title_label}
          source_label={source_label}
          content_only={content_only}
          highlight_indexes={highlight_indexes}
          unix={unix}
        />
      );
    }

    if (href.startsWith('/redirect')) {
      const url = new URL(`https://www.needl.ai${href}`);
      const context = decodeURIComponent(url.searchParams.get('context'));
      const link = decodeURIComponent(url.searchParams.get('link'));
      const title_label = url.searchParams.get('title_label');
      const source_label = url.searchParams.get('source_label');
      const content_only = url.searchParams.get('content_only');
      const unix = url.searchParams.get('unix');
      const highlight_indexes = JSON.parse(
        url.searchParams.get('highlight_indexes')
      );

      return (
        <CitationBase
          href={link}
          context={context}
          id={children as number}
          title_label={title_label}
          source_label={source_label}
          content_only={content_only}
          highlight_indexes={highlight_indexes}
          unix={unix}
        />
      );
    }
  }

  return (
    <a
      href={href}
      className='text-emerald-900 underline body-sm-bold'
      target='_blank'
      rel='noopener noreferrer'
    >
      {children}
    </a>
  );
};

const ReportCustomLink = ({
  href,
  children,
}: React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  if (href) {
    if (href.startsWith('/preview')) {
      const url = new URL(`https://www.needl.ai${href}`);
      const document_id = url.searchParams.get('document_id');
      const access_key = url.searchParams.get('access_key');
      const category_tab = url.searchParams.get('category_tab');
      const source = url.searchParams.get('source');
      const context = decodeURIComponent(url.searchParams.get('context'));
      const title_label = url.searchParams.get('title_label');
      const content_only = url.searchParams.get('content_only');
      const unix = url.searchParams.get('unix');
      const highlight_indexes = JSON.parse(
        url.searchParams.get('highlight_indexes') || '[]'
      );
      const mime_type = url.searchParams.get('mime_type');
      const document_highlight = url.searchParams.get('document_highlight')
        ? JSON.parse(url.searchParams.get('document_highlight'))
        : undefined;

      return (
        <Citation
          document_id={document_id}
          access_key={access_key}
          category_tab={category_tab}
          source={source}
          context={context}
          id={children as number}
          title_label={title_label}
          source_label={''}
          content_only={content_only}
          highlight_indexes={highlight_indexes}
          unix={unix}
          isReportCitation={true}
          mime_type={mime_type}
          document_highlight={document_highlight}
        />
      );
    }

    if (href.startsWith('/redirect')) {
      const url = new URL(`https://www.needl.ai${href}`);
      const context = decodeURIComponent(url.searchParams.get('context'));
      const link = decodeURIComponent(url.searchParams.get('link'));
      const title_label = url.searchParams.get('title_label');
      const source_label = url.searchParams.get('source_label');
      const content_only = url.searchParams.get('content_only');
      const unix = url.searchParams.get('unix');
      const highlight_indexes = JSON.parse(
        url.searchParams.get('highlight_indexes')
      );

      return (
        <CitationBase
          href={link}
          context={context}
          id={children as number}
          title_label={title_label}
          source_label={source_label}
          content_only={content_only}
          highlight_indexes={highlight_indexes}
          unix={unix}
        />
      );
    }
  }

  return (
    <a
      href={href}
      className='text-emerald-900 underline body-sm-bold'
      target='_blank'
      rel='noopener noreferrer'
    >
      {children}
    </a>
  );
};

const StyledUnorderedList = ({
  children,
}: React.HTMLAttributes<HTMLUListElement>) => {
  return (
    <ul className='flex flex-col gap-y-2 my-2 ml-4 list-disc text-sm'>
      {children}
    </ul>
  );
};

const StyledOrderedList = ({
  children,
}: React.OlHTMLAttributes<HTMLOListElement>) => {
  return (
    <ol className='flex flex-col gap-y-2 my-2 ml-4 list-decimal text-sm'>
      {children}
    </ol>
  );
};

const StyledParagraph = ({
  children,
}: React.HTMLAttributes<HTMLParagraphElement>) => {
  return <p className='text-sm'>{children}</p>;
};

const StyledHeading = ({
  children,
}: React.HTMLAttributes<HTMLHeadingElement>) => {
  return <h1 className='headline-lg-bold'>{children}</h1>;
};

const StyledSubHeading = ({
  children,
}: React.HTMLAttributes<HTMLHeadingElement>) => {
  return <h2 className='headline-small-bold'>{children}</h2>;
};

const StyledSubSubHeading = ({
  children,
}: React.HTMLAttributes<HTMLHeadingElement>) => {
  return <h3 className='headline-xs-bold'>{children}</h3>;
};

const StyledTable = ({ children }: React.HTMLAttributes<HTMLTableElement>) => {
  return <table className='border border-emerald-900'>{children}</table>;
};

const StyledTableHead = ({
  children,
}: React.HTMLAttributes<HTMLTableSectionElement>) => {
  return <thead className='bg-emerald-900 text-white'>{children}</thead>;
};

const StyledTableHeader = ({
  children,
}: React.HTMLAttributes<HTMLTableHeaderCellElement>) => {
  return <th className='border border-emerald-900 p-2'>{children}</th>;
};

const StyledTableData = ({
  children,
}: React.HTMLAttributes<HTMLTableDataCellElement>) => {
  return <td className='border border-emerald-900 p-2'>{children}</td>;
};
