// @ts-strict-ignore
import * as React from 'react';

import type { Event } from './event';

export const useCustomEventListener = (
  event: Event,
  callback: (data: unknown) => void
) => {
  const callbackRef = React.useRef(callback);

  React.useEffect(() => {
    const handler = (e) => {
      callbackRef.current(e.detail);
    };

    window.addEventListener(event, handler);

    return () => {
      window.removeEventListener(event, handler);
    };
  }, [event]);

  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);
};
