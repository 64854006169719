import { useSelectReportFileIds } from '@/features/NeedlReport/hooks/useReport';

export const NeedlReportSelectedSources = () => {
  const { selectedIds } = useSelectReportFileIds();

  if (!selectedIds || selectedIds.length === 0) {
    return null;
  }

  return (
    <div className='text-xs text-gray-700'>{`${selectedIds.length} source${
      selectedIds.length === 1 ? '' : 's'
    }`}</div>
  );
};
