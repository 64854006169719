import type * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { Icon } from '@/atoms/Icon';
import { IconContainer } from '@/atoms/IconContainer/IconContainer';
import { Text } from '@/atoms/Text/Text';
import { IconButton } from '@/components/IconButton/IconButton';
import CloseIcon from '@/icons/close-lg.svg?react';

export type ModalHeaderType = {
  /**
   * should render a bottom divide border?
   */
  divide?: boolean;
  /**
   * modal header title
   */
  title?: React.ReactNode;
  /**
   * icon
   */
  icon?: React.ReactNode;
  /**
   * modal toggle event
   */
  onDismiss?: () => void;
  /**
   * icon size
   */
  iconsize?: 'large' | 'medium' | 'small' | 'xs';
};

const Container = styled.div<Pick<ModalHeaderType, 'divide'>>`
  ${tw`flex justify-between items-center py-4 pl-6 pr-4`}
  ${({ divide }) => divide && tw`border-solid border-gray-300 border-b`}
`;

const StyledText = styled(Text)`
  ${tw`headline-normal-bold`}
`;

const CloseButton = styled(IconButton)`
  ${tw`ml-auto`}
`;

const StlyedIcon = styled(CloseIcon)`
  ${tw`text-gray-700`}
`;

export const ModalHeader = ({
  title,
  icon,
  onDismiss,
  divide = false,
  iconsize = 'medium',
}: ModalHeaderType): JSX.Element => {
  return (
    <Container divide={divide}>
      {icon || title ? (
        <div className='flex flex-1 space-x-2 items-center'>
          {icon ? (
            <IconContainer className='flex' color='gray'>
              <Icon icon={icon} size={iconsize} />
            </IconContainer>
          ) : null}
          {title ? (
            <StyledText bold dark color='gray'>
              {title}
            </StyledText>
          ) : null}
        </div>
      ) : null}
      {onDismiss ? (
        <CloseButton
          aria-label='close modal'
          onClick={onDismiss}
          size='large'
          id='modal-close-button'
        >
          <StlyedIcon tabIndex={-1} />
        </CloseButton>
      ) : null}
    </Container>
  );
};
