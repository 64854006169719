import * as React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { useOverlayType } from '@/components/AppOverlay';
import {
  NEEDL_UNIVERSE_ID,
  useAskNeedlDispatchContext,
} from '@/features/ChatBotV2/context';
import { ASK_NEEDL_ACTIONS } from '@/features/ChatBotV2/hooks/useAskNeedlState';
import {
  useReportFeedId,
  useReportPage,
} from '@/features/NeedlReport/hooks/useReport';

export const useChatHandler = (search: boolean) => {
  const dispatch = useAskNeedlDispatchContext();

  const overlayType = useOverlayType();
  const urlLocation = useLocation();

  const { reportId } = useReportPage();
  const { reportFeedId } = useReportFeedId();

  const {
    params: { category, source },
  } = useRouteMatch<{
    category: string;
    source: string;
  }>(['/:category/:source/:subCategory']) || {
    params: {},
  };

  const {
    params: { deeplink },
  } = useRouteMatch<{
    preview: string;
    previewSource: string;
    deeplink: string;
  }>(['/:preview/:previewSource/:deeplink']) || {
    params: {},
  };

  const urlParams = React.useMemo(
    () => new URLSearchParams(urlLocation.search),
    [urlLocation.search]
  );

  const handleSelectionChat = React.useCallback(() => {
    let feedId =
      source === NEEDL_UNIVERSE_ID ? NEEDL_UNIVERSE_ID : source || 'home';

    if (reportId && reportFeedId) {
      feedId = reportFeedId;
    }

    dispatch({
      type: ASK_NEEDL_ACTIONS.SET_CURRENT_FEED_OR_DOCUMENT_ID,
      payload: {
        searchWithin: 'feed',
        currentFeedOrDocumentId: feedId,
      },
    });
  }, [dispatch, source, reportFeedId, reportId]);

  const handleNeedlUniverseChat = React.useCallback(() => {
    dispatch({
      type: ASK_NEEDL_ACTIONS.SET_CURRENT_FEED_OR_DOCUMENT_ID,
      payload: {
        searchWithin: 'feed',
        currentFeedOrDocumentId: NEEDL_UNIVERSE_ID,
      },
    });
  }, [dispatch]);

  const handleWebSearch = () => {
    dispatch({ type: ASK_NEEDL_ACTIONS.TOGGLE_WEB_SEARCH });
  };

  const handleDocumentChat = React.useCallback(() => {
    const documentId = urlParams.get('overlay-deeplink') ?? deeplink;
    if (!documentId) return;
    dispatch({
      type: ASK_NEEDL_ACTIONS.SET_CURRENT_FEED_OR_DOCUMENT_ID,
      payload: {
        searchWithin: 'document',
        currentFeedOrDocumentId: documentId,
      },
    });
  }, [dispatch, deeplink, urlParams]);

  React.useEffect(() => {
    if (overlayType === 'preview' || category === 'preview') {
      handleDocumentChat();
    } else if (search && !reportId) {
      handleNeedlUniverseChat();
    } else {
      handleSelectionChat();
    }
  }, [
    handleNeedlUniverseChat,
    handleSelectionChat,
    handleDocumentChat,
    source,
    category,
    overlayType,
    search,
    reportId,
  ]);

  return { handleWebSearch, overlayType, category };
};
